// import { useState } from "react";

import ProductSection from "../components/ProductSection";

import BannerSlider from "./BannerSlider";

// import FeaturedProduct from "./FeaturedProduct";
import { useSelector } from "react-redux";
import FeaturedProduct from "./FeaturedProduct";

// import ReviewCardPage from "./ReviewCardPage";

const Home = () => {
  const { items: productData } = useSelector((state) => state.products);

  const { items: categoryData } = useSelector((state) => state.category);

  const combinedData = categoryData?.map((category) => ({
    ...category,
    products: productData?.filter(
      (product) => +product?.category_id === category?.id
    ),
  }));

  return (
    <div className="container mx-auto py-4 2xl:px-0 xl:px-14  ">
      <BannerSlider />

      {/* <FeaturedProduct
        visible={visible}
        handleSeeMore={handleSeeMore}
        handleless={handleless}
      /> */}

      <FeaturedProduct />

      <ProductSection combinedData={combinedData} />

      {/* <Banner /> */}
    </div>
  );
};

export default Home;
