import { useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";

// import Card from "../components/Card";
// import Card from "../components/Card";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import FeaturedCard from "../components/FeaturedCard";
const FeaturedProduct = () => {
  const { items: data } = useSelector((state) => state.featuredProduct);

  return (
    <div className="my-10 w-full ">
      <SectionTitle title={"Featured Products"} />
      <div className="flex justify-center">
        <Swiper
          className="mySwiper"
          spaceBetween={25}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },

            330: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },

            1024: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 5,
            },
          }}
        >
          {data.map((item) => (
            <SwiperSlide>
              <FeaturedCard key={item.id} product={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default FeaturedProduct;
