import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

const SubCat = ({
  activeIndex,
  getSubcategoriesByCategoryId,
  handleActiveState,
}) => {
  const { items: category } = useSelector((state) => state.category);
  // const { items: subCat } = useSelector((state) => state.subCategor);

  return (
    <div className="category-thumb category-container rounded p-2 flex flex-col gap-3 h-full  bg-white   md:h-[21rem] 2xl:h-[30rem] overflow-y-scroll">
      {category.map((category, index) => (
        <div
          key={category.id}
          className="mr-4"
          onClick={() => handleActiveState(index)}
        >
          <span className="cursor-pointer flex items-center gap-2 font-semibold text-black/60  ">
            <div className="image w-[35px] h-[35px] rounded-full overflow-hidden ">
              <img
                src={`${process.env.REACT_APP_URL}/uploads/category/${category.icon}`}
                alt={category.alt}
                className="w-full h-full object-cover"
              />
            </div>
            <p className="text-sm lg:text-base">{category.name}</p>

            <IoIosArrowDown
              className={`text-[1.5rem] transition-all duration-300 ${
                activeIndex === index && "rotate-[180deg]"
              }`}
            />
          </span>

          <ul className={`${activeIndex === index ? "" : "hidden"}`}>
            <li className="flex flex-col justify-center items-start ml-9  ">
              {getSubcategoriesByCategoryId(category.id).map(
                (subCat, subIndex) => (
                  <Link
                    // onClick={() => setshowCategoryMenu(false)}
                    key={subIndex}
                    to={`subcategory/${subCat.id}`}
                    className="border-b w-full text-gray-800 py-2 px-2 font-medium hover:bg-gray-300 rounded-md duration-300"
                  >
                    {subCat.name}
                  </Link>
                )
              )}
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
};

export default SubCat;
