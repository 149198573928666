import { currencyFormatter } from "../utils/currencyFormatter";
import { useDispatch } from "react-redux";
import {
  addToSingleCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { BsCartCheck } from "react-icons/bs";
const Card = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const addToCartHandler = (product) => {
  //   dispatch(addtoCart(product));
  //   navigate("/checkout");
  // };

  const [cartQuantity] = useState(1);

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(
      addToSingleCart({
        ...product,
        cartQuantity,
        variantPrice: product?.unit_price,
      })
    );
    navigate("/checkout");
  };
  return (
    <div className="flex flex-col gap-4 border-2 p-3 md:w-[17rem]  w-[9.5rem] hover:border-[#4cb14f] duration-300">
      <Link
        to={`/productdetails/${product.id}`}
        className=" h-[10rem] overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
          alt=""
          className="w-full h-full object-cover"
        />
      </Link>

      <div className="product-details flex flex-col justify-center gap-2.5">
        <Link
          to={`/productdetails/${product.id}`}
          className="font-semibold cursor-pointer hover:text-[#4cb14f] truncate duration-300 "
        >
          {product?.name}
        </Link>
        <span className="text-base  flex gap-2">
          <span className="font-semibold text-[#2b792e] text-xl">
            {currencyFormatter(+product?.unit_price)}
          </span>
        </span>
        <div className="w-full  flex items-center justify-center rounded-md bg-[#4cb14f] group-hover:bg-[#55bb58] duration-300">
          <button
            onClick={handleBuyNow}
            className="py-2 px-2 flex justify-center items-center gap-1  md:gap-2 text-white  md:text-base font-normal md:font-medium"
          >
            <div className="flex justify-center gap-2">
              <span className="flex justify-center items-center">
                <BsCartCheck />
              </span>
              <span className="text-[12px] md:text-base flex justify-center items-center">
                অর্ডার করুন
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
